<template>
  <card-container backButton closeButton>
    <div class="flex flex-col h-full px-12 pt-24 pb-10">
      <div class="text-3xl text-center">Logg inn</div>
      <form class="flex flex-col items-center gap-2 mt-10" @submit.prevent="signIn()">
        <input
          type="text"
          ref="emailInput"
          v-model="email"
          placeholder="Email"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': emailInvalid }"
        />
        <input
          type="password"
          ref="passwordInput"
          v-model="password"
          placeholder="Password"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': passwordInvalid }"
        />
        <input
          type="submit"
          :value="loading ? 'Logger inn...' : 'Logg inn'"
          :disabled="loading"
          class="px-10 py-4 mt-8 text-lg bg-gray-200 rounded-full disabled:opacity-50"
        />
      </form>
      <div class="flex-grow mt-6">
        <transition name="fade">
          <div v-show="error" class="text-center text-red-600">{{ errorMessage }}</div>
        </transition>
      </div>
      <div class="flex justify-center gap-6 mt-6">
        <button
          @click="$router.push({ name: 'reset-password', params: { transition: 'slide-forward' } })"
        >
          Glemt passord
        </button>
        <button
          @click="$router.push({ name: 'register', params: { transition: 'slide-forward' } })"
        >
          Opprett bruker
        </button>
      </div>
    </div>
  </card-container>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import CardContainer from '../components/CardContainer.vue'

export default {
  name: 'USerSignIn',
  components: { CardContainer },
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const error = ref(false)
    const errorMessage = ref('')

    const email = ref('')
    const emailInput = ref()
    const emailInvalid = ref(false)

    const password = ref('')
    const passwordInput = ref()
    const passwordInvalid = ref(false)

    const signIn = () => {
      loading.value = true
      error.value = false
      errorMessage.value = ''
      emailInvalid.value = false
      passwordInvalid.value = false
      store
        .dispatch('signIn', { email: email.value.trim(), password: password.value.trim() })
        .then(() => {
          router.push({ name: 'menu', params: { transition: 'slide-forward' } })
        })
        .catch(err => {
          loading.value = false
          error.value = true
          switch (err.code) {
            case 'auth/invalid-email':
              emailInput.value.focus()
              emailInvalid.value = true
              errorMessage.value = 'Ugyldig e-postadresse'
              break
            case 'auth/user-not-found':
              emailInput.value.focus()
              emailInvalid.value = true
              errorMessage.value = 'Ingen bruker registrert med denne adressen'
              break
            case 'auth/wrong-password':
              password.value = ''
              passwordInput.value.focus()
              passwordInvalid.value = true
              errorMessage.value = 'Passord er ikke riktig'
              break
            default:
              errorMessage.value = err.message
          }
        })
    }

    return {
      loading,
      error,
      errorMessage,
      email,
      emailInput,
      emailInvalid,
      password,
      passwordInput,
      passwordInvalid,
      signIn,
    }
  },
}
</script>
